import {useSearchParams} from '@github-ui/use-navigate'
import type React from 'react'
import {createContext, useCallback, useContext, useMemo, useState} from 'react'

import {useUpdateUserDiffViewPreferenceMutation} from '../hooks/mutations/use-update-user-diff-view-preference-mutation'
import {CommentsPreference} from '../types'
import type {DiffViewSettings, DiffLineSpacing, SplitPreference} from '../types'

const defaultViewSettings: DiffViewSettingsContextProps = {
  hideWhitespace: false,
  splitPreference: 'split',
  lineSpacing: 'relaxed',
  commentsPreference: CommentsPreference.Visible,
  updateCommentsPreference: () => {},
  updateHideWhitespace: () => {},
  updateSplitPreference: () => {},
  updateLineSpacing: () => {},
}

type DiffViewSettingsContextProps = {
  updateCommentsPreference: (newPreference: CommentsPreference) => void
  updateHideWhitespace: (newPreference: boolean) => void
  updateSplitPreference: (newPreference: SplitPreference) => void
  updateLineSpacing: (newPreference: DiffLineSpacing) => void
} & DiffViewSettings

const DiffViewSettingsContext = createContext<DiffViewSettingsContextProps>(defaultViewSettings)

export function DiffViewSettingsProvider({
  viewSettings,
  children,
}: React.PropsWithChildren<{viewSettings: DiffViewSettings}>) {
  // user + query params preferences
  const userSplitPreference = useSplitViewPreference(viewSettings.splitPreference)
  const userWhitespaceParam = useWhitespacePreference(viewSettings.hideWhitespace)
  const userLineSpacing = viewSettings.lineSpacing

  const {mutate: updateUserDiffViewPreference} = useUpdateUserDiffViewPreferenceMutation()

  // context state variables
  const [hideWhitespace, setHideWhitespace] = useState(userWhitespaceParam)

  // TODO: As we are using mutation with Tan Stack Query for these fields, we should move to accessing this data inside of a Tan Stack Query query key instead of local state. Work logged in https://github.com/github/pull-requests/issues/15547.
  const [commentsPreference, setCommentsPreference] = useState<CommentsPreference | undefined>(
    viewSettings.commentsPreference,
  )
  const [lineSpacing, setLineSpacing] = useState<DiffLineSpacing>(userLineSpacing)
  const [splitPreference, setSplitPreference] = useState(userSplitPreference)

  // update user's diff split preference
  const updateSplitPreference = useCallback(
    async (newPreference: SplitPreference) => {
      updateQueryParam('diff', newPreference)
      setSplitPreference(newPreference)
      updateUserDiffViewPreference({splitPreference: newPreference})
    },
    [updateUserDiffViewPreference],
  )

  // update user's diff comments preference
  const updateCommentsPreference = useCallback(
    async (newPreference: CommentsPreference) => {
      setCommentsPreference(newPreference)
      updateUserDiffViewPreference({commentsPreference: newPreference})
    },
    [updateUserDiffViewPreference],
  )

  // TODO update persisted user whitespace preference
  const updateHideWhitespace = useCallback((newPreference: boolean) => {
    updateQueryParam('w', newPreference ? '1' : '0')
    setHideWhitespace(newPreference)
  }, [])

  const updateLineSpacing = useCallback(
    async (newPreference: DiffLineSpacing) => {
      setLineSpacing(newPreference)
      updateUserDiffViewPreference({lineSpacing: newPreference})
    },
    [updateUserDiffViewPreference],
  )

  const diffViewSettings = useMemo(
    () => ({
      commentsPreference,
      hideWhitespace,
      splitPreference,
      lineSpacing,
      updateCommentsPreference,
      updateHideWhitespace,
      updateSplitPreference,
      updateLineSpacing,
    }),
    [
      commentsPreference,
      hideWhitespace,
      lineSpacing,
      splitPreference,
      updateCommentsPreference,
      updateHideWhitespace,
      updateLineSpacing,
      updateSplitPreference,
    ],
  )

  return <DiffViewSettingsContext.Provider value={diffViewSettings}>{children}</DiffViewSettingsContext.Provider>
}

export function useDiffViewSettings() {
  return useContext(DiffViewSettingsContext)
}

function useSplitViewPreference(defaultPreference: SplitPreference): SplitPreference {
  let splitPreference = defaultPreference

  const [searchParams] = useSearchParams()
  const splitPreferenceParam = searchParams.get('diff')

  if (splitPreferenceParam === 'split' || splitPreferenceParam === 'unified') {
    splitPreference = splitPreferenceParam
  }

  return splitPreference
}

function useWhitespacePreference(defaultPreference: boolean): boolean {
  let hideWhitespace = defaultPreference

  const [searchParams] = useSearchParams()
  const whitespaceParam = searchParams.get('w')

  if (whitespaceParam === '1') {
    hideWhitespace = true
  } else if (whitespaceParam === '0') {
    hideWhitespace = false
  }

  return hideWhitespace
}

function updateQueryParam(paramName: string, value: string) {
  const url = new URL(window.location.href, window.location.origin)

  if (value) {
    const encodedValue = encodeURIComponent(value)
    url.searchParams.set(paramName, encodedValue)
  } else {
    url.searchParams.delete(paramName)
  }
  window.history.replaceState({path: url.toString()}, '', url.toString())
}

try{ DiffViewSettingsContext.displayName ||= 'DiffViewSettingsContext' } catch {}
try{ DiffViewSettingsProvider.displayName ||= 'DiffViewSettingsProvider' } catch {}