import {Button} from '@primer/react'
import type {RefObject} from 'react'

import {DialogStateProvider} from '../contexts/DialogStateContext'
import type {
  CommentAuthor,
  CommentingImplementation,
  ConfigureSuggestedChangesImplementation,
  DiffAnnotation,
  ThreadSummary,
} from '../types'
import type {InlineReviewThreadProps} from './InlineReviewThread'
import {InlineReviewThread} from './InlineReviewThread'

export interface InlineMarkersProps
  extends Pick<
    InlineReviewThreadProps,
    'batchingEnabled' | 'batchPending' | 'repositoryId' | 'subjectId' | 'subject' | 'viewerData'
  > {
  annotations: DiffAnnotation[]
  commentingImplementation: CommentingImplementation
  conversationListThreads: ThreadSummary[]
  fileAnchor?: string
  filePath: string
  hideFromFocus: boolean
  isMarkerListOpen: boolean
  onCloseConversationDialog: () => void
  onCloseConversationList: () => void
  onCloseFocusMode: () => void
  enterDialogMode: (shouldFocusStartCommentButton?: boolean) => void
  onThreadSelected: (threadId: string) => void
  onAnnotationSelected: (annotationId: string) => void
  returnFocusRef: RefObject<HTMLElement>
  selectedThreadId?: string | null
  selectedAnnotationId?: string | null
  threadsConnectionId?: string
  suggestedChangesConfig?: ConfigureSuggestedChangesImplementation
  ghostUser?: CommentAuthor
}

export function InlineMarkers(props: InlineMarkersProps) {
  return (
    <DialogStateProvider>
      <InlineMarkersInternal {...props} />
    </DialogStateProvider>
  )
}

function InlineMarkersInternal({
  annotations,
  commentingImplementation,
  conversationListThreads,
  hideFromFocus = true,
  isMarkerListOpen,
  fileAnchor,
  filePath,
  onAnnotationSelected,
  onCloseConversationDialog,
  onCloseConversationList,
  onCloseFocusMode,
  onThreadSelected,
  returnFocusRef,
  selectedAnnotationId,
  selectedThreadId,
  threadsConnectionId,
  suggestedChangesConfig,
  ghostUser,
  enterDialogMode,
  ...rest
}: InlineMarkersProps) {
  return (
    <div style={{marginLeft: '-20px', marginRight: '-94px', maxWidth: '633px', width: '100%'}}>
      {!hideFromFocus && (
        <Button
          variant="default"
          size="small"
          // Using onMouseUp instead of onClick to custom handle onKeyUp events
          onMouseUp={onCloseFocusMode}
          onKeyUp={e => {
            // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
            if (e.key === 'Enter' || e.code === 'Space') onCloseFocusMode()
          }}
          // TODO: convert this to css classname
          style={{
            position: 'absolute',
            left: '-40px',
            top: '-1px',
          }}
        >
          Exit
        </Button>
      )}
      {conversationListThreads.map(thread => (
        <InlineReviewThread
          enterDialogMode={enterDialogMode}
          key={`review-thread-${thread.id}`}
          commentingImplementation={commentingImplementation}
          filePath={filePath}
          fileAnchor={fileAnchor}
          threadId={thread.id}
          hideFromFocus={hideFromFocus}
          isOutdated={thread.isOutdated}
          threads={conversationListThreads}
          threadsConnectionId={threadsConnectionId}
          onThreadSelected={onThreadSelected}
          suggestedChangesConfig={suggestedChangesConfig}
          ghostUser={ghostUser}
          {...rest}
        />
      ))}
    </div>
  )
}

try{ InlineMarkers.displayName ||= 'InlineMarkers' } catch {}
try{ InlineMarkersInternal.displayName ||= 'InlineMarkersInternal' } catch {}