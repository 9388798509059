export enum CommentsPreference {
  Visible = 'visible',
  Collapsed = 'collapsed',
}
export type DiffLineSpacing = 'compact' | 'relaxed'
export type SplitPreference = 'split' | 'unified'

export type DiffViewSettings = {
  // TODO: remove this once we have a way to persist the user's comments preference and expose it in the API
  commentsPreference?: CommentsPreference
  hideWhitespace: boolean
  splitPreference: SplitPreference
  lineSpacing: DiffLineSpacing
}

export type CommitNotices = 'compact_diff_lines'

export type UserNotice<T> = {
  name: T
  dismissed: boolean
}
