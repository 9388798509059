import {ActionList} from '@primer/react'
import {useFeatureFlags} from '@github-ui/react-core/use-feature-flag'

import {useDiffViewSettings} from '../contexts/DiffViewSettingsContext'
import {CommentsPreference} from '../types'

export function DiffCommentsPreferenceToggle() {
  const {diff_inline_comments: diffInlineCommentsFeatureIsEnabled} = useFeatureFlags()

  const {commentsPreference, updateCommentsPreference} = useDiffViewSettings()

  if (!diffInlineCommentsFeatureIsEnabled) return null

  return (
    <ActionList.Group selectionVariant="single">
      <ActionList.GroupHeading>Comments</ActionList.GroupHeading>
      <ActionList.Item
        selected={commentsPreference === CommentsPreference.Collapsed}
        onSelect={() => updateCommentsPreference(CommentsPreference.Collapsed)}
      >
        Collapsed
      </ActionList.Item>
      <ActionList.Item
        selected={commentsPreference === CommentsPreference.Visible}
        onSelect={() => updateCommentsPreference(CommentsPreference.Visible)}
      >
        Always visible
      </ActionList.Item>
    </ActionList.Group>
  )
}

try{ DiffCommentsPreferenceToggle.displayName ||= 'DiffCommentsPreferenceToggle' } catch {}