import {reactFetchJSON} from '@github-ui/verified-fetch'
import {useMutation} from '@github-ui/react-query'
import type {DiffLineSpacing, CommentsPreference, SplitPreference} from '../../types'

type RequestBody = {
  commentsPreference?: CommentsPreference
  lineSpacing?: DiffLineSpacing
  diff?: SplitPreference
}

export function useUpdateUserDiffViewPreferenceMutation() {
  const apiURL = `/users/diffview`

  return useMutation({
    mutationFn: async ({
      commentsPreference,
      lineSpacing,
      splitPreference,
    }: {
      commentsPreference?: CommentsPreference
      lineSpacing?: DiffLineSpacing
      splitPreference?: SplitPreference
    }) => {
      let body: RequestBody = {}

      if (commentsPreference) body = {...body, commentsPreference}
      if (lineSpacing) body = {...body, lineSpacing}
      if (splitPreference) body = {...body, diff: splitPreference}

      const result = await reactFetchJSON(`${apiURL}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body,
      })
      const json = await result.json()
      if (result.ok) return json
      const errorMessage = json.error || 'Unknown error occurred'
      throw new Error(errorMessage, {cause: result.status})
    },
  })
}
